header {
  background: $white;
  * {
    background-color: inherit;
  }
}
.top_navigation {
  display: flex;
}
.navbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 23px 0 36px;
  width: 100%;
  z-index: 100;
  .navbar_collapse {
    @media (max-width: $lg) {
      margin: 40px auto;
    }
  }
  .navbar_toggler {
    border: none;
    outline: none;
  }
  .btn_cta {
    padding: 10px 26px;
    margin: 10px auto;
    @media (min-width: $xl) {
      margin: 0;
    }
  }
}
.nav-link {
  font-family: "Roboto", sans-serif;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0em;
  text-align: right;
  color: $darkblue;
  &.active {
    color: $naviblue !important;
    font-weight: 700;
  }
}

.logo {
  justify-self: start;
  img {
    width: 86px;
    height: auto;
  }
}

.navbar_light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar_light .navbar-brand:focus,
.navbar_light .navbar-brand:hover {
  color: rgba(0, 0, 0, 0.9);
}
.navbar_light .navbar_nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar_light .navbar_nav .nav-link:focus,
.navbar_light .navbar_nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7);
}
.navbar_light .navbar_nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar_light .navbar_nav .active > .nav-link,
.navbar_light .navbar_nav .nav-link.active,
.navbar_light .navbar_nav .nav-link.show,
.navbar_light .navbar_nav .show > .nav-link {
  color: rgba(0, 0, 0, 0.9);
}
.navbar_light .navbar_toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar_toggler_icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar_light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar_light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar_light .navbar-text a:focus,
.navbar_light .navbar-text a:hover {
  color: rgba(0, 0, 0, 0.9);
}
@media (max-width: 1199.98px) {
  .navbar_expand_xl > .container,
  .navbar_expand_xl > .container-fluid,
  .navbar_expand_xl > .container-lg,
  .navbar_expand_xl > .container-md,
  .navbar_expand_xl > .container-sm,
  .navbar_expand_xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar_expand_xl {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar_expand_xl .navbar_nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar_expand_xl .navbar_nav .dropdown-menu {
    position: absolute;
  }
  .navbar_expand_xl .navbar_nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar_expand_xl > .container,
  .navbar_expand_xl > .container-fluid,
  .navbar_expand_xl > .container-lg,
  .navbar_expand_xl > .container-md,
  .navbar_expand_xl > .container-sm,
  .navbar_expand_xl > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar_expand_xl .navbar_collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar_expand_xl .navbar_toggler {
    display: none;
  }
}
.navbar_brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar_toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  outline: none;
  border-radius: 0.25rem;
  cursor: pointer;
}
.navbar_toggler:focus,
.navbar_toggler:hover {
  text-decoration: none;
  outline: none;
}
.navbar_nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav-item {
  display: flex;
  justify-content: center;
}
.navbar_nav .nav-link {
  padding: 12px 0;
}
.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-align: center;
  align-items: center;
}
.navbar_toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar_toggler:focus,
.navbar_toggler:hover {
  text-decoration: none;
}
.navbar_toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}
.justify_content_end {
  justify-content: end;
}
.navbar_collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-align: center;
  align-items: center;
  @media (max-width: $xl - 1px) {
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    height: 90vh;
    position: fixed;
  }
}
.collapse:not(.show) {
  display: none;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}
