@media (min-width: $xxl) {
  .careers {
    .hero {
      margin-bottom: 105px;
      .hero_grid {
        grid-template-columns: 1fr 546px;
        gap: 46px;
        margin: 30px auto;
        .hero_text {
          padding: 40px 0 0;
          max-width: 620px;
        }
      }
    }
  }
}
.section_learning {
  margin-top: 174px;
  .section_info h2 {
    font-size: 36px;
  }
  @media (min-width: $md) {
    margin-top: 274px;
  }
  @media (min-width: $lg) {
    margin-top: 374px;
  }
  @media (min-width: $xxl) {
    margin-top: 392px;
    .hero_grid  {
      gap: 35px;
      .section_info {
        padding: 94px 42px 134px;
        box-sizing: content-box;
        width: 527px;
      }
    }
  }
}

.employment_grid {
  display: grid;
  justify-content: center;
  margin-top: 100px;
  @media (min-width: $md) {
    gap: 30px;
    grid-template-columns: 1fr 1fr;
  }
  .employment_item {
    position: relative;
    .shape {
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      transform: skewY(-4deg);
    }
    &:nth-child(even) {
      transform: translateY(100px);
      @media (min-width: $md) {
        transform: translateY(225px);
      }
    }
    .employment_box {
      padding: 100px 40px;
      display: grid;
      gap: 24px;
      h2 {
        font-size: 36px;
        font-weight: 700;
      }
      img {
        position: absolute;
        right: 30px;
        top: -55px;
      }
    }
  }
}
