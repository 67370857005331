.technologies .section_mono {
  padding-top: 90px;
  h2,
  p {
    max-width: 816px;
  }
  .image_mono {
    margin: 0 auto;
    padding-bottom: 55px;
    max-width: 638px;
  }
  .btn_cta {
    margin-top: 0;
  }
}
