.site_footer {
  background: $darkblue;
  border-radius: 0 100px 0 0;
  * {
    line-height: 1.5;
  }
}
.footer_grid {
  display: grid;
  padding: 30px;
  @media (min-width: 768px) {
    grid-template-columns: 2fr 1fr 1fr;
    gap: 30px;
    padding: 30px;
  }
  @media (min-width: 1024px) {
    grid-template-columns: 3fr 2fr 1fr;
    gap: 50px;
    padding: 50px;
  }
  @media (min-width: 1440px) {
    gap: 100px;
    padding: 100px;
  }
  * {
    color: white;
  }
  .footer_col {
    display: grid;
    gap: 25px;
    grid-template-rows: 80px auto;
  }
}
.footer_nav {
  display: grid;
  row-gap: 10px;
  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
}
