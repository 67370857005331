.blog_header {
  position: relative;
  text-align: center;
  margin: 50px auto 50px;
  max-width: 556px;
  @media (min-width: $md) {
    margin: 90px auto 72px;
  }

  h1 {
    margin-bottom: 24px;
    font-size: 46px;
    line-height: 64px;
    font-weight: 500;
    @media (min-width: $md) {
      font-size: 56px;
    }
  }
  p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  }
  .shape_1 {
    @media (min-width: $md) {
      position: absolute;
      top: -10px;
      left: -90px;
    }
    @media (min-width: $lg) {
      position: absolute;
      top: -10px;
      left: -190px;
    }
    @media (min-width: $xxl) {
      position: absolute;
      top: -10px;
      left: -290px;
    }
  }
  .shape_2 {
    @media (min-width: $md) {
      position: absolute;
      bottom: -10px;
      right: -90px;
    }
    @media (min-width: $lg) {
      position: absolute;
      bottom: -10px;
      right: -190px;
    }
    @media (min-width: $xxl) {
      position: absolute;
      bottom: -10px;
      right: -290px;
    }
  }
}
.post_title {
  font-weight: 700;
  color: $gray_02;
}
.post_card.first {
  display: grid;
  gap: 30px;
  @media (min-width: $lg) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: $xxl) {
    grid-template-columns: 707px 472px;
    gap: 60px;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 112px;
  }
  .first_info_outer {
    display: flex;
    justify-content: space-between;
    .post_title {
      font-size: 36px;
      line-height: 43px;
    }
    .label {
      padding: 10px 19px;
      border-radius: 14px;
      background-color: #fefad8;
      color: #978c26;
      font-size: 12px;
      font-weight: 500;
      width: max-content;
      height: max-content;
      margin: auto 0;
    }
  }
}
.posts_grid {
/*   display: flex;
  flex-direction: row;
  flex-wrap: wrap; */
  display: grid;
  @media (min-width: $sm) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: $lg) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  justify-content: center;
  max-width: 1400px;
  margin: 21px auto 153px;
  column-gap: 30px;
  row-gap: 55px;
  .post_title {
    font-size: 24px;
    line-height: 32px;
  }
  .post_card {
    display: grid;
    max-width: 393px;
    overflow: hidden;
    gap: 24px;
    .post_info {
      display: grid;
      gap: 16px;
    }
    .author_card {
      grid-template-columns: 55px auto auto;
      overflow: hidden;
      .image_outer {
        max-width: 393px;
        max-height: 360px;
        overflow: hidden;
        border-radius: 5px;
        .post_image {
          justify-self: center;
          //height: auto;
          max-width: initial;
        }
      }
    }
  }
}
.avatar_outer {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  overflow: hidden;
}
.author_avatar {
  width: 100%;
  height: auto;
}
.text_box {
  display: grid;
  gap: 24px;
}
.author_card {
  display: grid;
  grid-template-columns: 55px 1fr auto;
  gap: 18px;
  align-items: center;
  justify-content: start;
}
.author_info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  p {
    color: $darkblue;
    padding: 0;
    margin: 0;
  }
  time {
    color: $gray_06;
  }
}
.post_cta {
  display: flex;
  gap: 24px;
  height: 38px;
}
.post_category {
  width: max-content;
  padding: 8px 17px;
  background: #fde4ef;
  color: #be7490;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
}
.post_url {
  height: 38px;
  width: max-content;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  line-height: 1.5;
  padding: 10px 14px;
  border-radius: 5px;
  color: $gray_10;
  &:hover {
    color: $naviblue;
    background: $gray_10;
    border: 1px solid $naviblue;
  }
}
/* .justify-content-end {
  justify-content: end;
} */
.wrapper_posts {
  max-width: $lg;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.top_pagination {
  display: flex;
  justify-content: center;
  max-width: 1240px;
  margin: auto;
  @media (min-width: $xxl) {
    .pagination {
      margin-left: auto;
    }
  }
}
.bottom_pagination {
  display: flex;
  justify-content: center;
  max-width: 1240px;
  margin: auto;
}
.pagination ul {
  display: flex;
  list-style: none;
  gap: 26px;
  a {
    svg path {
      fill: #00b894;
    }
    &.disabled svg path {
      fill: #cbd2d9;
    }
  }
}
.search {
  display: flex;
  .search_input {
    display: block;
    margin-left: auto;
    width: 287px;
    padding: 17px 28px;
    outline: none;
    border: none;
  }
  .search_input_submit {
    margin-right: auto;
    border: none;
    outline: none;
    padding: 0 16px;
    @media (min-width: $lg) {
      margin-right: 10px;
    }
  }
}
.results {
  position: relative;
}
#results-container {
  position: absolute;
  padding: 0;
  background: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  @media (min-width: $lg) {
    grid-template-columns: 1fr 1fr;
  }
  .s_box {
    padding: 30px;
    max-width: 600px;
  }
}
