.jobs {
  .hero_grid {
    margin-top: 35px;
    max-width: $xxl - 170px;
    margin-left: auto;
    margin-right: auto;
  }
  .hero_text {
    max-width: 515px;
    justify-self: start;
    padding: 0;
  }
  .hero_visual {
    @media (min-width: $lg) {
      justify-self: end;
    }
  }
  .video_placeholder {
    position: relative;
    svg:nth-child(1) {
      position: absolute;
      right: 0;
      display: none;
    }
    svg:nth-child(2) {
      position: absolute;
      bottom: -120px;
      left: 30px;
      display: none;
    }
    svg:nth-child(3) {
      position: absolute;
      top: 5px;
      left: 70px;
      display: none;
    }
  }
  @media (min-width: $xxl) {
    .hero {
      margin-bottom: 250px;
    }
    .v_placeholder {
      position: relative;
      top: 33px;
      right: 33px;
    }
    .video_placeholder {
      svg:nth-child(1) {
        display: block;
      }
      svg:nth-child(2) {
        display: block;
      }
      svg:nth-child(3) {
        display: block;
      }
    }
  }
}
.job_card {
  display: grid;
  gap: 24px;
  max-width: 877px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 36px;
  padding: 20px;
  @media (min-width: $xxl) {
    padding: 56px 83px 72px;
  }
  ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  .card_footer {
    display: grid;
    @media (min-width: $md) {
      grid-template-columns: 2fr 1fr 1fr;
    }
    a:hover {
      color: #dff;
    }
  }
}
.job {
  max-width: $md - 40px;
  padding: 20px 16px 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  @media (min-width: $md) {
    margin-bottom: 130px;
  }
  h1,
  h2,
  h3 {
    font-weight: 700;
    color: $darkblue;
    line-height: 49px;
    margin-bottom: 10px;
  }
  h1 {
    font-size: 28px;
    margin-top: 0;
    @media (min-width: $md) {
      margin-top: 110px;
    }
  }
  h2 {
    font-size: 18px;
    margin-top: 36px;
  }
  h3 {
    font-size: 16px;
    margin-top: 30px;
  }
  p,
  li {
    font-size: 16px;
    line-height: 1.5;
    color: $gray_02;
  }
}
