.hero {
  background: $light;
}
.page_title {
  font-size: 32px;
  line-height: 1.27;
  font-weight: 700;
  span {
    color: white;
    background: $naviblue;
    padding: 0 5px;
  }
  @media (min-width: $md) {
    font-size: 56px;
  }
}
////////////////////////////
.hero_grid {
  display: grid;
  gap: 20px;
  @media (min-width: $lg) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: $xxl) {
    grid-template-columns: 556px 687px;
    gap: 15px;
  }
}
.jobs .hero_grid {
  @media (min-width: $lg) {
    grid-template-columns: 1fr 1fr;
  }
}
/////////////////////////////
.section_double_grid {
  @media (min-width: $xxl) {
    grid-template-columns: 55% 45%;
    padding: 30px 64px;
  }
}
.section_double_grid-1 {
  @media (min-width: $xxl) {
    grid-template-columns: 65% 1fr;
    gap: 75px;
    padding: 0;
    transform: translateX(-150px);
    .hero_visual {
      transform: translateX(75px);
      margin-top: -45px;
    }
    .section_info {
      padding: 0;
      width: 432px;
      .btn_cta {
        display: block;
        margin-top: 48px;
        padding: 18px 64px;
      }
    }
  }
}
.section_double_grid-2 {
  .figs {
    display: none;
  }
  .btn_cta {
    margin-bottom: 30px;
  }
  @media (min-width: $xxl) {
    transform: translateY(30px);
    grid-template-columns: 715px 725px;
    gap: 0;
    max-width: $xxl;
    padding: 0;
    .hero_visual {
      padding: 33px 100px 0 0 !important;
    }
    .section_info {
      padding: 60px 138px 60px 145px;
      * {
        max-width: 438px;
      }
    }
    .figs {
        display: grid;
        justify-items: center;
        gap: 16px;
        position: absolute;
        top: 116px;
        right: 34px;
    }
    .btn_cta {
      display: block;
      margin-top: 48px;
      padding: 18px 64px;
    }
  }
}
.section_double_grid-3 {
  @media (min-width: $xxl) {
    transform: translateY(-10px);
    transform: translateX(65px);
    grid-template-columns: 580px 1fr;
    gap: 60px;
    padding: 0;
    .btn_cta {
      display: block;
      margin-top: 48px;
      padding: 18px 64px;
    }
  }
}

.hero_text,
.hero_visual {
  place-self: center;
  @media (min-width: $md) {
    padding: 20px;
  }
}
@media (min-width: $xxl) {
  .hero_visual {
    padding: 0;
  }
  .hero_text {
    padding: 0 0 112px 0;
  }
}
.hero_visual img {
  max-width: 100%;
  height: auto;
}
.hero_text {
  display: grid;
  align-items: center;
  p {
    margin-top: 20px;
    font-size: 1rem;
    line-height: 1.5;
  }
}
.btn_cta {
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: none;
  outline: none;
  padding: 18px 40px;
  margin-top: 56px;
  font-size: 1rem;
  font-weight: 700;
  text-decoration: none;
  text-align: center;
  width: max-content;
  svg {
    margin-left: 20px;
  }
  &:hover {
    color: $accent_02;
  }
}
