@import 
  './0-base/colors',
  './0-base/sizes',
  './0-base/reset';
@import 
  './1-pages/about',
  './1-pages/blog',
  './1-pages/careers',
  './1-pages/jobs',
  './1-pages/post',
  './1-pages/portfolio',
  './1-pages/team',
  './1-pages/technologies',
  './1-pages/careers';
@import 
  './2-components/header',
  './2-components/hero',
  './2-components/section',
  './2-components/search',
  './2-components/footer';
@import 
  './3-helpers/additional';
//@import 
  //'./4-vendors/bootstrap-reboot.min',
  //'./4-vendors/bootstrap.min',
  //'./4-vendors/bootstrap-grid.min';