.team_member {
  max-width: 1200px;
  margin: auto;
  background: $white;
  @media (min-width: $xxl) {
    .hero_grid {
      grid-template-columns: 300px 1fr;
    }
  }
  .member_info {
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    //gap: 15px;
  }
  .social {
    padding: 10px 0 20px;
    display: flex;
    gap: 15px;
    a {
      width: 30px;
    }
  }
}
.posts_list {
  max-width: 1130px;
  margin: 40px auto;
  h2 {
    font-size: 30px;
    line-height: 1.5;
    margin-top: 24px;
    font-weight: 500;
  }
  h3 {
    font-size: 24px;
    line-height: 1.5;
  }
  .author_posts {
    display: grid;
    gap: 50px;
    li {
      display: grid;
      gap: 20px;
      place-content: center;
      @media (min-width: $md) {
        grid-template-columns: 1fr 4fr;
        img {
          place-self: center;
        }
      }
      .text {
        display: grid;
        align-content: center;
        gap: 10px;
      }
    }
  }
}
