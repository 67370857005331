@media (min-width: $xxl) {
  .portfolio {
    .section_styled {
      margin-bottom: 105px;
      .hero_grid {
        grid-template-columns: 1fr 1fr;
        gap: 30px;
        margin: 30px auto;
        .section_info {
          padding: 40px 0 0 0;
          width: 499px;
          .section_title {
            font-size: 48px;
            font-weight: 500;
            line-height: 1;
          }
          .section_subtitle {
            padding-top: 10px;
            font-size: 18px;
            font-weight: 700;
            line-height: 49px;
          }
          .descr_title {
            font-weight: 700;
            font-size: 28px;
            padding-top: 30px;
          }
        }
      }
    }
    .twix {
      margin-top: 125px;
    }
    .hero .hero_grid .hero_visual {
        align-self: end;
    }
  }
}
