section {
  margin-bottom: 50px;
  //padding-top: 50px;
  @media (min-width: $md) {
    margin-bottom: 100px;
  }
  @media (min-width: $xxl) {
    margin-bottom: 150px;
  }
}
.centered {
  max-width: 100%;
  width: max-content;
  margin-left: auto;
  margin-right: auto;
}
.section_info {
  display: grid;
  align-items: center;
  padding: 20px 15px;
  p {
    margin-top: 20px;
    font-size: 1rem;
    line-height: 1.5;
  }
  @media (min-width: $md) {
    padding: 30px;
  }
  @media (min-width: $xxl) {
    padding: 60px;
  }
}
.section_title {
  font-size: 32px;
  line-height: 1.5;
  @media (min-width: $md) {
    font-size: 56px;
    line-height: 62px;
  }
}

.justify_end {
  justify-self: end;
}
.rounded_darkblue {
  background: $darkblue;
  border-radius: 112px 0 202px 0;
  padding: 60px 30px;
  .hero_visual {
    padding: 30px;
    img {
      transform: translateY(-70px);
    }
  }
  @media (min-width: $xl) {
    border-radius: 202px 0 202px 0;
    padding: 0 15px;
  }
}

.section_mono {
  padding-top: 60px;
  @media (min-width: $lg) {
    margin-top: 175px;
  }
  .mono_box {
    display: grid;
  }
  h2,
  p {
    text-align: center;
    width: 100%;
    max-width: 432px;
    justify-self: center;
    margin-bottom: 20px;
    margin-right: auto;
    margin-left: auto;
  }
  p {
    font-size: 16px;
    line-height: 1.5;
  }
  .image_mono {
    width: 100%;
    max-width: 800px;
    margin: 40px auto 20px;
  }
  .btn_cta {
    display: block;
    justify-self: center;
  }
}
.life_mono {
  .image_mono {
    max-width: 872px;
    margin: 40px auto 4px;
  }
  .btn_cta {
    padding: 18px 64px;
  }
}
/* contact section */

.section_contact .contact_grid {
  position: relative;
  width: 95%;
  max-width: 1240px;
  margin: 150px auto;
  display: grid;
  gap: 10px;
  grid-auto-rows: fit-content;
  padding: 10px;
  @media (min-width: $sm) {
    padding: 20px;
  }
  @media (min-width: $md) {
    padding: 40px;
  }
  @media (min-width: $lg) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: $xxl) {
    gap: 15px;
  }
  background: $naviblue;
  border-radius: 16px;
  .section_info {
    display: block;
    * {
      color: white;
    }
    h3 {
      font-weight: 700;
      font-size: 24px;
      line-height: 1.5;
      margin-top: 60px;
      margin-bottom: 30px;
    }
    .info_line {
      display: flex;
      b {
        vertical-align: top;
        position: relative;
        display: inline-block;
        margin-right: 13px;
        margin-bottom: 0px;
        font-size: 108px;
        line-height: 0.3;
      }
      &.n1 b {
        color: $accent_02 !important;
      }
      &.n2 b {
        color: $skyblue;
      }
      &.n3 b {
        color: $accent_04;
      }
    }
    .contact_figs {
      display: none;
      @media (min-width: $xl) {
        display: grid;
        gap: 16px;
        position: absolute;
        bottom: 120px;
      }
    }
  }
  .form_outer {
    background: white;
    border-radius: 16px;
    //max-width: 584px;
    /*     @media (min-width: $xxl) {
      padding: 40px;
    } */
  }
  .contact_form {
    width: 100%;
    display: grid;
    gap: 20px;
    padding: 20px;
    * {
      font-size: 1rem;
      line-height: 1.375;
    }
    input,
    textarea {
      padding: 17px;
      background: $gray_10;
      color: $text_gray;
      border: 1px solid $lightblue;
      border-radius: 8px;
    }
    label {
      color: $gray_02;
    }
    .btn_cta {
      padding: 16px 48px;
      margin-top: 20px;
    }
  }
}

/* technologies */

.brick {
  border-radius: 10px;
  max-width: 947px;
  padding: 40px 30px 35px;
  display: grid;
  gap: 24px;
  @media (min-width: $md) {
    padding: 40px 65px 35px;
  }
  @media (min-width: $xxl) {
    &:nth-child(even) {
      transform: translateX(-80px);
    }
    &:nth-child(odd) {
      transform: translateX(80px);
    }
  }
  &:not(:last-child) {
    margin-bottom: 36px;
  }
}


/* common props*/
.bg_darkblue {
  background: $darkblue;
  h2 {
    color: $gray_10;
  }
  p {
    color: $gray_08;
  }
}
.bg_naviblue {
  background: $naviblue;
  h2 {
    color: $gray_10;
  }
  p {
    color: $gray_08;
  }
}
.bg_blue_01 {
  background: $blue_01;
  h2 {
    color: $gray_10;
  }
  p {
    color: $gray_08;
  }
}
.bg_light_0 {
  background: $light_00;
  h2,
  h3 {
    color: $gray_02;
  }
  p {
    color: $gray_04;
  }
}
@media (max-width: $md - 1px) {
  .btn_cta {
    margin-left: auto;
    margin-right: auto;
  }
}
/* portfolio */
.twix {
  display: grid;
  @media (min-width: $md) {
    grid-template-columns: 1fr 1fr;
  }
  .solution,
  .result {
    position: relative;
    padding: 20px;
    @media (min-width: $md) {
      max-width: 529px;
      padding: 30px;
    }
    @media (min-width: $lg) {
      padding: 75px 48px;
    }
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    h4 {
      font-size: 28px;
      font-weight: 700;
      line-height: 48px;
      margin-bottom: 15px;
    }
    p {
      font-size: 16px;
      line-height: 24px;
    }
  }
  .solution {
    z-index: 1;
    @media (min-width: $md) {
      transform: translate(8px, -19px);
    }
    img {
      position: absolute;
      top: 32px;
      right: 62px;
    }
  }
  .result {
    transform: translate(0, 19px);
    img {
      display: none;
    }
    @media (min-width: $md) {
      transform: translate(-8px, 19px);
      img {
        display: block;
        position: absolute;
        bottom: 61px;
        right: 63px;
      }
    }
    .sources {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 28px;
      align-items: center;
      .btn_cta {
        height: max-content;
        padding: 8px 32px;
        margin: 0;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        &_call {
          padding: 18px 40px;
        }
      }
      .related {
        display: grid;
        .rel_solution {
          color: $gray_10;
        }
        .rescue {
          color: $accent_03;
        }
      }
    }
  }
}
.btn_cta_learn {
  padding: 18px 64px;
}
@media (min-width: $xxl) {
  .life_team {
    padding: 70px 160px;
    grid-template-columns: 440px 1fr;
    .section_info {
      padding: 0;
    }
  }
}
