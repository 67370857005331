$naviblue  : #004FAC;
$darkblue  : #293663;
$skyblue   : #399DFF;
$lightblue : #A0BCF8;
$blue_01   : #3B4A7D;
$blue_02   : #5D71B8;
$blue_06   : #C5E2FF;
$blue_08   : #E8F3FF;
$light     : #F0FAFF;
$light_00:   #F5FAFF;
$gray_02   : #323F4B;
$gray_03   : #3E4C59;
$gray_04   : #52606D;
$gray_06   : #7B8794;
$gray_08   : #CBD2D9;
$gray_09   : #E4E7EB;
$gray_10   : #F5F7FA;
$accent_01 : #74B9FF;
$accent_02 : #81ECEC;
$accent_03 : #00B894;
$accent_04 : #575FCF;
$accent_81 : #74B9FF;
$text_gray : #999999;
$white     : #ffffff;

.colored {
  .section_title, h4, h2  {
    color: $gray_10;
  }
  .section_subtitle {
    color: $accent_02;
  }
  p {
    color: $gray_09;
  }
}

.bg_light {
  background-color: $light;
}
.bg_light_00 {
  background-color: $light_00;
}

.bg_white {
  background-color: $white !important;
}

.bg_light_00 {
  background-color: $light_00 !important;
}
.bg_blue_02 {
  background-color: $blue_02 !important;
}
.bg_blue_08 {
  background-color: $blue_08 !important;
}
.bg_accent_01 {
  background-color: $accent_01 !important;
}
.bg_accent_04 {
  background-color: $accent_04 !important;
}
.bg_accent_81 {
  background-color: $accent_81 !important;
}
.accent_02 {
  color: $accent_02;
  svg path {
    fill: $accent_02;
  }
}
.gray_02 {
  color: $gray_02;
  svg path {
    fill: $gray_02;
  }
}
.gray_03 {
  color: $gray_03;
  svg path {
    fill: $gray_03;
  }
}
.gray_04 {
  color: $gray_04;
  svg path {
    fill: $gray_04
  }
}
.gray_06 {
  color: $gray_04;
  svg path {
    fill: $gray_06
  }
}
.gray_08 {
  color: $gray_08;
  svg path {
    fill: $gray_08
  }
}
.gray_09 {
  color: $gray_09;
  svg path {
    fill: $gray_09;
  }
}
.gray_10 {
  color: $gray_10;
  svg path {
    fill: $gray_10;
  }
}
.blue_06 {
  color: $blue_06;
  svg path {
    fill: $blue_06;
  }
}
.blue_08 {
  color: $blue_08;
  svg path {
    fill: $blue_08;
  }
}
.darkblue {
  color: $darkblue;
  svg path {
    fill: $darkblue;
  }
}
.naviblue {
  color: $naviblue;
  svg path {
    fill: $naviblue;
  }
}
.white {
  color: $white;
  svg path {
    fill: $white;
  }
}