.about-us {
  @media (min-width: $xxl) {
    .section_mono {
      margin-top: 150px;
      padding-top: 0;
      margin-bottom: 80px;
    }
    .hero_grid {
      grid-template-columns: 1fr 1fr;
    }
  }
  .about_hero {
    width: 100%;
    @media (min-width: $md) {
      margin-top: 5px;
    }
  }
  .section_info {
    .info_1 p,
    .info_2 p {
      text-align: left;
    }
    @media (min-width: $xxl) {
      padding: 20px 60px;
    }
  }
}
.hero_team_grid {
  background-image: url(/assets/images/about_curves.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 95%;
  position: relative;
  display: grid;
  padding: 88px 0;
  margin-top: -80px;
  margin-bottom: 65px;
  .layer_1 {
    width: 100%;
    padding-bottom: 50px;
    display: grid;
    gap: 2%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-items: center;
    .hero_avatar {
      width: 60px;
      height: 60px;
      overflow: hidden;
      border-radius: 50%;
      img {
        width: 100%;
        height: auto;
      }
      @media (min-width: $lg) {
        &:nth-child(1) {
          place-self: center end;
          transform: translate(40px, 100px);
        }
        &:nth-child(2) {
          place-self: center start;
          transform: translate(-30px, 50px);
        }
        &:nth-child(3) {
          place-self: center end;
          transform: translate(-10px, 90px);
        }
        &:nth-child(4) {
          place-self: center end;
          transform: translate(-70px, 75px);
        }
      }
    }
  }
  .layer_2 {
    width: 100%;
    display: grid;
    gap: 5%;
    grid-template-columns: 1fr 1fr 1fr;
    .hero_avatar {
      overflow: hidden;
      border-radius: 50%;
      &:nth-child(1) {
        place-self: center center;
        width: 76px;
        height: 76px;
      }
      &:nth-child(2) {
        place-self: center center;
        width: 99px;
        height: 99px;
      }
      &:nth-child(3) {
        place-self: center center;
        width: 76px;
        height: 76px;
      }
      @media (min-width: $lg) {
        &:nth-child(1) {
          transform: translate(70px, 60px);
        }
        &:nth-child(2) {
          transform: translate(30px, 60px);
        }
        &:nth-child(3) {
          transform: translate(-50px, 80px);
        }
      }
    }
  }
  @media (min-width: $lg) {
    margin-top: -180px;
    .layer_1 {
      gap: 10%;
      grid-template-columns: 1fr 3fr 3fr 1fr;
    }
    .layer_2 {
      gap: 10%;
    }
  }
}
.cta_box {
  display: flex;
  gap: 12px;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;
  .btn_cta {
    width: 212px;
    margin-top: 36px;
  }
}

.values_grid {
  margin-left: auto;
  margin-right: auto;
  display: grid;
  gap: 20px;
  width: 96%;
  max-width: 1182px;
  h3 {
    font-weight: 700;
    font-size: 36px;
    line-height: 49px;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
  }
  @media (min-width: $md) {
    max-width: 1256px;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    .value:nth-child(even) {
      transform: translateY(-30px);
    }
  }
}

.team_grid {
  display: grid;
  max-width: 1254px;
  .tm_outer {
    max-width: 613px;
  }
  .team_member {
    padding-top: 50px;
    max-width: 613px;
    display: grid;
    align-items: center;
    justify-content: center;
    .tm_image_outer {
      padding-top: 30px;
      .tm_image {
        z-index: 15;
        display: block;
        margin-left: auto;
        margin-right: auto;
        border-radius: 5px;
      }
    }
    .origin {
      font-size: 14px;
      font-weight: 500;
      text-align: right;
    }

    .position {
      text-align: left;
      margin-bottom: 10px;
    }
  }
  .tm_info {
    height: 293px;
    padding: 15px;
    a {
      text-decoration: none;
    }
  }

  @media (min-width: $md) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
  }
  @media (min-width: $xxl) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 28px;
    grid-row-gap: 22px;
    .team_member {
      grid-template-columns: 218px 1fr;
    }
    h3 {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
    }
    .tm_info {
      display: grid;
      grid-template-rows: 32px 32px 32px 1fr 60px;
      width: 482px;
      border-radius: 5px;
      position: relative;
      padding: 15px 45px 15px 125px;
      transform: translateX(-44px);
    }
    .origin {
      font-size: 14px;
      font-weight: 500;
      text-align: right;
    }
    .tm_image_outer {
      z-index: 100;
      padding-top: 0 !important;
      background: transparent;
      transform: translateX(44px);
    }
  }
}
.social_link {
  margin-right: 5px;
  &.gray_10 svg path {
    fill: $gray_10;
  }
  &.gray_02 svg path {
    fill: $gray_02;
  }
}
