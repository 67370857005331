$sm: 560px;
$md: 768px;
$lg: 920px;
$xl: 1200px;
$xxl: 1440px;

.wrapper {
  max-width: $xxl - 170px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: $xxl - 1px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.wrapper_f {
  max-width: $xxl;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: $xxl - 1px) {
    padding-left: 15px;
    padding-right: 15px;
  }
  .section_info {
    max-width: 560px;
  }
  @media (min-width: $xxl) {
    padding: 70px 160px;
    gap: 80px !important;
  }
}

img {
  max-width: 100%;
}
body {
  overflow-x: hidden;
}
* {
  box-sizing: border-box;
}
.rounded-0-90-0-90 {
  border-radius: 0 90px 0 90px;
}
.rounded_10 {
  border-radius: 10px;
}
.rounded_15 {
  border-radius: 15px;
}
.rounded_25 {
  border-radius: 25px;
}
.pt_0 {
  padding-top: 0;
}
.pt_20 {
  padding-top: 20px !important;
}
.p_10 {
  padding: 10px;
}
.p_20 {
  padding: 20px;
}
.p_30 {
  padding: 30px;
}
.px_102 {
  @media (min-width: $xxl) {
    padding-left: 102px;
    padding-right: 102px;
  }
}
.py_52 {
  padding-top: 52px;
  padding-bottom: 52px;
}
.p_43_36 {
  padding: 43px 36px;
}
.mt-lg-0,
.my-lg-0 {
  margin-top: 0 !important;
}
.w400 {
  font-weight: 400;
}
.w500 {
  font-weight: 500;
}
.w700 {
  font-weight: 700;
}
.container_fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.justify_content_between {
  justify-content: space-between !important;
}
.justify_content_around {
  justify-content: space-around !important;
}
.justify_content_center {
  justify-content: center !important;
}
.justify_content_end {
  justify-content: end !important;
}
.justify_content_start {
  justify-content: start !important;
}
.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}
.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}
.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}
.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}
.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}
.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}
.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}
.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}
.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}
.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}
h2 {
  font-size: 36px;
  font-weight: 700;
}